<template>
  <v-container>
    <h3 class="mx-7 mb-7">{{ $t("message.config-detail") }}</h3>
    <!-- data table -->
    <v-row class="mx-7">
      <v-col cols="12" md="3">
        {{ $t("message.label-id") }}
      </v-col>
      <v-col cols="12" md="8">
        {{ configDetail.id }}
      </v-col>
      <v-col cols="12" md="3">
        {{ $t("message.label-description") }}
      </v-col>
      <v-col cols="12" md="8">
        {{ configDetail.description }}
      </v-col>
      <v-col cols="12" md="3">
        {{ $t("message.code") }}
      </v-col>
      <v-col cols="12" md="8">
        {{ configDetail.code }}
      </v-col>
      <v-col cols="12" md="3">
        {{ $t("message.value") }}
      </v-col>
      <v-col cols="12" md="8">
        {{ configDetail.value }}
      </v-col>
    </v-row>
    <v-card class="d-flex justify-space-between mb-6 mt-9 mx-7" flat tile>
      <v-card class="pa-2" flat tile>
        <v-btn color="grey" depressed @click="$router.go(-1)">
          {{ $t("message.btn-back") }}
        </v-btn>
      </v-card>
      <v-card class="pa-2" flat tile>
        <v-btn color="primary" depressed @click="toUpdate(configDetail.id)">
          {{ $t("message.btn-update") }}
        </v-btn>
      </v-card>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "ConfigDetail",
  data: () => ({}),
  computed: {
    configDetail() {
      return this.$store.state.config_module.configDetail;
    },
  },

  created() {
    this.dispatchConfigDetail();
  },

  methods: {
    dispatchConfigDetail() {
      let id = this.$route.params.id;
      this.$store.dispatch("config_module/fetchConfigDetail", { id: id });
    },
    toUpdate(id) {
      this.$router.push(`/config/${id}/update`)
    },
  },
};
</script>

<style></style>
